<template>
	<div class="shop">
		<div>
			<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList" style="padding:15px 15px 50px 15px;height: 100%;">
				<div class="listTime" v-for="item in chatsList" @click="goChat(item)">


					<van-image v-if="item.store.image" :src="imageUrl +item.store.image" class="store-image"></van-image>
					<van-image v-else src="" class="store-image"></van-image>

					<div class="store-content">
						<div class="title">


							<p>{{item.store.name}}</p>

							<div class="time">
								<p v-if="item.num">{{item.num}}</p>
								<p v-else style="background-color: #FFFFFF;"></p>
								<!-- <p>{{item.update_time.split(" ")[0]}}</p> -->
							</div>
						</div>
						<div class="infoBox">
							<p>{{item.last_message}}</p>
						</div>
					</div>

				</div>
			</van-list>
		</div>
	</div>
</template>
<script>
	import {
		collectList,
		delList,
		getChatList
	} from '@/api/user'
	import {
		toUrl
	} from '@/utils/tools'

	import {
		Dialog
	} from "vant";
	export default {
		data() {
			return {
				shopType: '',
				nullTip: '空空如也~',
				imageUrl: this.url.imageUrl,
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				chatsList: [],
             title:''
			}
		},
		mounted() {
			// 
			this.getChatList()
		},
		// beforeRouteLeave(to) {
			
		// 		to.meta.title = this.title
			
		// },
		methods: {
			getChatList() {
				let params = {}
				params.page = this.page
				params.limit = this.limit
				getChatList(params).then((res) => {
					console.log(res)
					// this.chatsList = res.data.data
					const rows = res.data.data
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束 
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.chatsList = rows
					} else {
						this.chatsList = this.chatsList.concat(rows)
					}
				})
			},
			goChat(item) {
				this.title = item.store.name
				this.$router.push({
					path: '/chatPage',
					query: {
						storeName: item.store.name,
						gid: item.id
					}
				})
			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getChatList()
			},
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.shop {
		background-color: #f6f6f6;

		.listTime {
			display: flex;

			align-items: center;
			justify-content: space-between;
			background-color: #FFFFFF;
			border-radius: 5px;
			margin-bottom: 10px;
			padding: 10px 15px;
			height: 88px;
			box-sizing: border-box;
			border-radius: 5px;


			.store-image {
				width: 64px;
				height: 60px;
				border-radius: 6px;
			}

			.store-content {
				display: flex;
				flex-direction: column;
				width: 70%;
				height: 100%;
			}

			.title {
				display: flex;
				margin-bottom: 20px;

				img {
					width: 20px;
					height: 20px;
				}

				p {
					color: #333333;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					margin: 0;
				}
			}

			.infoBox {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;

				p {
					color: #999999;
					font-weight: 400;
				}
			}

			.time {
				display: flex;

				p:nth-child(1) {
					margin: 0;
					font-size: 13px;
					background-color: red;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					text-align: center;
					color: #FFFFFF;
				}

				p:nth-child(2) {
					margin: 0 0 0 5px;
					font-size: 13px;
					color: #666;
				}
			}


			.infoBox {
				p {
					margin: 0;
					font-size: 14px;
					font-weight: 600;
				}
			}

			.messageBox {
				p {
					margin: 0;
					font-size: 12px;
					color: #666;
				}
			}
		}
	}
</style>
